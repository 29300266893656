export const AUTH_TOKEN_KEY = 'AUTH_TOKEN'
const X_ACCESS_TOKEN = 'x-access-token'

export const getAuthContext = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY)
  return {
    context: {
      headers: {
        [X_ACCESS_TOKEN]: token,
      },
    },
  }
}
