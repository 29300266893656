import styled from 'styled-components'

export const FolderContainer = styled.span`
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    user-select: none;
    .folder-name {
        padding: 0.2rem 0.5rem;
        background: #6e6e6e;
        color: #fff;
        font-size: 0.8rem;
        margin-top: 0.5rem;
    }
    svg {
        transition: transform 0.3s ease-in-out;
    }
    &:hover {
        svg {
            transform: scale(1.2);
        }
    }
`
