import React, { useState } from 'react'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@apollo/client'
import { TaskbarContainer } from './Taskbar.styled'
import LoginModal from '../LoginModal'
import { GET_ME } from '../../Queries/Auth'
import { getAuthContext } from '../../Utils/auth'
import EditModal from '../EditModal'

const Taskbar = () => {
  const [showLogin, setShowLogin] = useState<boolean>(false)
  const { data: meData } = useQuery(GET_ME, { ...getAuthContext() })
  return (
    <>
      <TaskbarContainer>
        <div className="left-side">
          <FontAwesomeIcon
            color="#333"
            icon={faCog}
            size="2x"
            onClick={() => setShowLogin(true)}
          />
        </div>
        <div className="right-side" />
      </TaskbarContainer>
      {meData?.me
        ? <EditModal show={showLogin} onClose={() => setShowLogin(false)} />
        : <LoginModal show={showLogin} onClose={() => setShowLogin(false)} />}
    </>
  )
}

export default Taskbar
