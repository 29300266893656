import styled from 'styled-components'

export const AppContainer = styled.div`
  @keyframes slide {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1.0);
    }
  }
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #333;
  animation: slide 0.3s ease-in;
  align-items: flex-start;
`
export const FolderContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-grow: 1;
  max-height: calc(100% - 50px);
`
