import React, { useEffect, useRef, useState } from 'react'
import { convertFromRaw, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { Folder } from '../../Queries/Folders'
import { ModalContainer } from './FolderWindow.styled'

export const OPEN_CLOSE_TIME = 0.15

let updateMousePos = false
let offsetX = 0
let offsetY = 0

export const FolderWindow = ({
  folder,
  onClose,
}: { folder: Folder, onClose: React.MouseEventHandler }) => {
  const [onCloseStart, setOnCloseStart] = useState<boolean>(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const updateCoordinates = (e: MouseEvent) => {
    if (!updateMousePos) {
      return
    }
    const { clientX, clientY } = e

    if (contentRef.current) {
      contentRef.current.style.left = `${clientX - offsetX}px`
      contentRef.current.style.top = `${clientY - offsetY}px`
    }
  }
  const disableMoveWindow = () => {
    updateMousePos = false
  }
  useEffect(() => {
    document.addEventListener('mouseup', disableMoveWindow)
    document.addEventListener('mousemove', updateCoordinates)
    if (contentRef.current) {
      const { width } = contentRef.current.getBoundingClientRect()
      contentRef.current.style.left = `calc(50% - ${width / 2}px)`
      contentRef.current.style.top = '5vh'
    }
    return () => {
      document.removeEventListener('mouseup', disableMoveWindow)
      document.removeEventListener('mousemove', updateCoordinates)
    }
  }, [])

  let editorState = null

  try {
    const parsedContent = JSON.parse(folder?.content?.content!)
    const contentSate = convertFromRaw(parsedContent)
    editorState = EditorState.createWithContent(contentSate)
  } catch {
    editorState = EditorState.createEmpty()
  }

  return (
    <ModalContainer isClosing={onCloseStart}>
      <div draggable onDragStart={(e) => e.preventDefault()} className="content" ref={contentRef}>
        <div
          className="toolbar"
          onMouseDown={(e) => {
            updateMousePos = true
            if (contentRef.current) {
              const { clientX, clientY } = e
              const { left, top } = contentRef.current.getBoundingClientRect()
              offsetX = clientX - left
              offsetY = clientY - top
            }
          }}
        >
          <div className="left-side" />
          <div className="right-side">
            <button
              onClick={() => {
                setOnCloseStart(true)
                setTimeout(onClose, (OPEN_CLOSE_TIME - 0.01) * 1000)
              }}
              type="button"
            >
              &times;

            </button>
          </div>
        </div>
        <Editor
          readOnly
          editorState={editorState}
          onChange={() => null}
          wrapperClassName="text"
          toolbarHidden
        />
      </div>
    </ModalContainer>
  )
}
