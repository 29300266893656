import styled from 'styled-components'

export const EditModalContainer = styled.div`
    position: fixed;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    visibility: ${(props: { show: boolean }) => (props.show ? 'visible' : 'hidden')};
    .content {
        position: relative;
        margin: 1rem;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 6px;
        width: 100%;
        padding: 1rem;
        .error {
            color: red;
            margin-bottom: 1rem;
        }
        h2 {
            margin-top: 0;
            margin-bottom: 1rem;
        }
        input {
            margin-bottom: 1rem;
            padding: 0.5rem;
            border-radius: 6px;
            border: 1px solid #ddd;
        }
        button {
            background: transparent;
            padding: 0.5rem;
            border-radius: 6px;
            border: 1px solid #333;
            transition: all 0.3s ease-in-out;
            &:enabled {
                cursor: pointer;
                &:hover {
                    background: #333;
                    color: #fff;
                }
            }
        }
        .close-button {
            position: absolute;
            top: -15px;
            right: -15px;
            color: #ddd;
            cursor: pointer;
        }
        .item-container {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 1rem;
                border: 1px solid #ddd;
                border-radius: 6px;
                margin-bottom: 1rem;
                user-select: none;
                cursor: pointer;
                color: #333;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .editor-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            .editor {
                flex-grow: 1;
            }
            .editor-window {
                height: auto;
                overflow-y: auto;
                max-height: calc(100vh - 250px);
                min-height: 250px;
                border: 1px solid #ddd;
            }
            .actions {
                display: flex;
                margin-top: 1rem;
                button {
                    flex-grow: 1;
                    &:last-of-type {
                        margin-left: 1rem;
                    }
                }
            }
        }
        .name-modal {
            margin: auto;
            width: 100%;
            max-width: 500px;
            input, button {
                width: 100%;
                margin-bottom: 1rem;
            }
        }
    }
`
