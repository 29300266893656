import { gql } from '@apollo/client'

export type Folder = Partial<{
    id: string,
    name: string,
    content: {
        id?: string,
        content?: string
    } | null
}>

export const GET_ALL_FOLDERS = gql`
    query {
        getAllFolders {
            id,
            name
        }
    }
`

export const GET_SINGLE_FOLDER = gql`
    query($id: Float!) {
        getOneFolder(id: $id) {
            id,
            name,
            content {
                id,
                content
            }
        }
    }
`
