import styled from 'styled-components'

export const TaskbarContainer = styled.div`
    height: 50px;
    background: #636363;
    width: 100%;
    border-top: 1px solid #999;
    display: flex;
    .left-side {
        display: flex;
        flex-grow: 1;
        align-items: center;
        svg {
            margin-left: 1rem;
            cursor: pointer;
            transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
            &:hover {
                transform: scale(1.2);
                color: #ffffff;
            }
        }
    }
    .right-side {
        flex-grow: 1;
    }
`
