import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { faMinusCircle, faPlusCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Editor } from 'react-draft-wysiwyg'
import {
  convertFromRaw, convertToRaw, EditorState,
} from 'draft-js'
import { Folder, GET_ALL_FOLDERS, GET_SINGLE_FOLDER } from '../../Queries/Folders'
import { EditModalContainer } from './EditModal.styled'
import { CREATE_CONTENT, UPDATE_CONTENT } from '../../Mutations/Content'
import { getAuthContext } from '../../Utils/auth'
import { CREATE_FOLDER, DELETE_FOLDER } from '../../Mutations/Folder'

const EditModal = ({ show, onClose }: { show: boolean, onClose: () => void }) => {
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null)
  const [showNameModal, setShowNameModal] = useState<boolean>(false)
  const [folderName, setFolderName] = useState<string>('')
  const [getAllFolders, { data: folderData }] = useLazyQuery(GET_ALL_FOLDERS, { fetchPolicy: 'network-only' })
  const [editorState, setEditorState] = useState<EditorState | undefined>(undefined)
  const [getFolder, { data: singleFolderData }] = useLazyQuery(GET_SINGLE_FOLDER, { fetchPolicy: 'network-only' })
  const [updateContent] = useMutation(UPDATE_CONTENT, { ...getAuthContext() })
  const [createContent] = useMutation(CREATE_CONTENT, { ...getAuthContext() })
  const [createFolder] = useMutation(CREATE_FOLDER, { ...getAuthContext() })
  const [deleteFolder] = useMutation(DELETE_FOLDER, { ...getAuthContext() })
  const getSingleFolderContent = async () => {
    if (!selectedFolder?.id) {
      return
    }
    getFolder({
      variables: { id: parseInt(selectedFolder.id, 10) },
    })
  }
  useEffect(() => {
    getAllFolders()
  }, [])
  useEffect(() => {
    if (selectedFolder && selectedFolder.id) {
      getSingleFolderContent()
    }
  }, [selectedFolder])
  useEffect(() => {
    if (singleFolderData?.getOneFolder) {
      try {
        const rawStateObj = JSON.parse(singleFolderData?.getOneFolder?.content?.content)
        const state = convertFromRaw(rawStateObj)
        setEditorState(EditorState.createWithContent(state))
      } catch {
        setEditorState(EditorState.createEmpty())
      }
    }
  }, [singleFolderData?.getOneFolder])
  const saveContent = () => {
    const content = JSON.stringify(convertToRaw(editorState?.getCurrentContent()!))
    const data = singleFolderData?.getOneFolder
    if (!data?.content) {
      createContent({
        variables: {
          id: parseInt(data?.content?.id, 10),
          content: { content, folderId: data?.id },
        },
      })
      setSelectedFolder(null)
      return
    }

    updateContent({
      variables: {
        id: parseInt(data?.content?.id, 10),
        content: { content },
      },
    })
    setSelectedFolder(null)
  }
  const renderFolders = () => (
    <div className="item-container">
      {folderData?.getAllFolders?.map((folder: Folder) => (
        <span
          className="item"
          key={folder.id}
          onClick={() => setSelectedFolder(folder)}
        >
          {folder.name}
          <FontAwesomeIcon
            icon={faMinusCircle}
            onClick={(e) => {
              e.stopPropagation()
              deleteFolder({
                variables: { id: parseInt(folder.id!, 10) },
              }).then(() => getAllFolders())
            }}
          />
        </span>
      ))}
      <span
        className="item"
        onClick={() => setShowNameModal(true)}
      >
        Add folder
        <FontAwesomeIcon icon={faPlusCircle} />
      </span>
    </div>
  )
  const renderContent = () => (
    <div className="editor-container">
      <Editor
        editorState={editorState}
        wrapperClassName="editor"
        editorClassName="editor-window"
        onEditorStateChange={(state) => setEditorState(state)}
      />
      <div className="actions">
        <button type="button" onClick={() => setSelectedFolder(null)}>Back</button>
        <button type="button" onClick={saveContent}>Save</button>
      </div>
    </div>
  )

  if (showNameModal) {
    return (
      <EditModalContainer show={show}>
        <div className="content">
          <div className="name-modal">
            <h2>Add a new folder</h2>
            <input placeholder="Write a name of the new folder..." onChange={(e) => setFolderName(e.target.value)} />
            <button
              type="button"
              onClick={() => {
                createFolder({
                  variables: { folder: { name: folderName } },
                }).then(() => getAllFolders())
                setShowNameModal(false)
              }}
            >
              Add
            </button>
            <button
              type="button"
              onClick={() => setShowNameModal(false)}
            >
              Back
            </button>
          </div>
        </div>
      </EditModalContainer>
    )
  }
  return (
    <EditModalContainer show={show}>
      <div className="content">
        <FontAwesomeIcon
          size="2x"
          className="close-button"
          icon={faWindowClose}
          onClick={() => {
            setSelectedFolder(null)
            onClose()
          }}
        />
        <h2>
          Content management
          {selectedFolder ? `: ${selectedFolder.name}` : ''}
        </h2>
        {selectedFolder === null ? renderFolders() : renderContent()}
      </div>
    </EditModalContainer>
  )
}

export default EditModal
