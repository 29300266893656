import { gql } from '@apollo/client'

export const CREATE_FOLDER = gql`
    mutation($folder: FolderInput!) {
        createFolder(folder: $folder) {
            id,
            name
        }
    }
`

export const DELETE_FOLDER = gql`
mutation($id: Float!) {
    deleteFolder(id: $id)
}
`
