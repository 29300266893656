import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Folder as FolderType, GET_ALL_FOLDERS, GET_SINGLE_FOLDER } from './Queries/Folders'
import { Folder } from './Components/Folder'
import { FolderWindow } from './Components/FolderWindow'
import Taskbar from './Components/Taskbar'
import { AppContainer, FolderContainer } from './App.styled'

const App = () => {
  const [selectedFolder, setSelectedFolder] = useState<FolderType | null>(null)
  const { data: allFoldersData } = useQuery(GET_ALL_FOLDERS)
  const { data: singleFolderData } = useQuery(GET_SINGLE_FOLDER, {
    variables: { id: parseInt(selectedFolder?.id || '', 10) },
    skip: selectedFolder === null,
    fetchPolicy: 'network-only',
  })
  return (
    <>
      <AppContainer>
        <FolderContainer>
          {allFoldersData?.getAllFolders.map((folder: FolderType) => (
            <Folder onClick={() => setSelectedFolder(folder)} key={folder.id} folder={folder} />
          ))}
        </FolderContainer>
        <Taskbar />
      </AppContainer>
      {singleFolderData?.getOneFolder ? (
        <FolderWindow
          onClose={() => setSelectedFolder(null)}
          folder={singleFolderData?.getOneFolder}
        />
      ) : null}
    </>
  )
}

export default App
