import styled from 'styled-components/macro'
import { OPEN_CLOSE_TIME } from '.'

export const ModalContainer = styled.div`
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .content {
        @keyframes scroll {
          from {
            transform: scaleY(0);
          } to {
            transform: scaleY(1.0);
          }
        }
        @keyframes scrollReverse {
          from {
            transform: scaleY(1.0);
          } to {
            transform: scaleY(0);
          }
        }
        position: absolute;
        background: #fff;
        border-radius: 6px;
        width: 100%;
        max-width: 760px;
        animation: ${(props: { isClosing: boolean }) => (props.isClosing
    ? `scrollReverse ${OPEN_CLOSE_TIME}s ease-out`
    : `scroll ${OPEN_CLOSE_TIME}s ease-out`)};
    }
    .toolbar {
      cursor: grab;
      display: flex;
      justify-content: space-between;
      background: #999;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border: 1px solid #888;
      padding: 0.25rem 0.5rem;
      button {
        background: none;
        border: none;
        color: #fff;
        cursor: pointer;
      }
    }
    .text {
      max-height: 85vh;
      overflow-y: auto;
      padding: 1rem;
      h1, h2, h3, h4, h5, h6, p, span {
        margin-top: 0;
      }
      img {
        width: 100%;
      }
    }
`
