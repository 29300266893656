import { gql } from '@apollo/client'

export const CREATE_CONTENT = gql`
    mutation($content: ContentInput!) {
        createContent(content: $content) {
            id,
            content,
            folder {
                id
            }
        }
    }
`

export const UPDATE_CONTENT = gql`
    mutation($id: Float! $content: ContentInput!) {
        updateContent(id: $id content: $content) {
            id,
            content,
            folder {
                id
            }
        }
    }
`
