import styled from 'styled-components'

export const LoginModalContainer = styled.div`
    position: fixed;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    visibility: ${(props: { show: boolean }) => (props.show ? 'visible' : 'hidden')};
    .content {
        position: relative;
        margin: auto;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 6px;
        width: 100%;
        max-width: 600px;
        padding: 1rem;
        .error {
            color: red;
            margin-bottom: 1rem;
        }
        h2 {
            margin-top: 0;
            margin-bottom: 1rem;
        }
        input {
            margin-bottom: 1rem;
            padding: 0.5rem;
            border-radius: 6px;
            border: 1px solid #ddd;
        }
        button {
            background: transparent;
            padding: 0.5rem;
            border-radius: 6px;
            border: 1px solid #333;
            transition: all 0.3s ease-in-out;
            &:enabled {
                cursor: pointer;
                &:hover {
                    background: #333;
                    color: #fff;
                }
            }
        }
        .close-button {
            position: absolute;
            top: -15px;
            right: -15px;
            color: #ddd;
            cursor: pointer;
        }
    }
`
