import { useLazyQuery, useMutation } from '@apollo/client'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { LOGIN } from '../../Mutations/Auth'
import { GET_ME } from '../../Queries/Auth'
import { AUTH_TOKEN_KEY, getAuthContext } from '../../Utils/auth'
import { LoginModalContainer } from './LoginModal.styled'

const LoginModal = ({ show, onClose }: { show: boolean, onClose: () => void }) => {
  const [tryLogin, setTryLogin] = useState<boolean>(false)
  const [loginDetails, setLoginDetails] = useState<{[key: string]: string}>({})
  const [loginFunction, { data: loginData }] = useMutation(LOGIN)
  const [getMe] = useLazyQuery(GET_ME, { ...getAuthContext() })
  useEffect(() => {
    if (tryLogin) {
      getMe()
    }
  }, [tryLogin])

  const setDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { name, value } } = e
    setLoginDetails((state) => {
      const newState = { ...state }
      newState[name] = value
      return newState
    })
  }

  const login = async () => {
    const { data } = await loginFunction({ variables: loginDetails })
    localStorage.setItem(AUTH_TOKEN_KEY, data?.login?.token)
    setTryLogin(true)
  }

  return (
    <LoginModalContainer show={show}>
      <div className="content">
        <FontAwesomeIcon size="2x" className="close-button" icon={faWindowClose} onClick={onClose} />
        <h2>Content management</h2>
        {loginData?.login === null ? <span className="error">Incorrect login details.</span> : null}
        <input placeholder="Email..." type="text" name="email" onChange={setDetails} />
        <input placeholder="Password..." type="password" name="password" onChange={setDetails} />
        <button disabled={!loginDetails.email || !loginDetails.password} type="button" onClick={login}>
          Log In
        </button>
      </div>
    </LoginModalContainer>
  )
}

export default LoginModal
