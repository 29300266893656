import React from 'react'
import { faFolder } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Folder as FolderType } from '../../Queries/Folders'
import { FolderContainer } from './Folder.styled'

export const Folder = ({
  folder,
  onClick,
}: { folder: FolderType, onClick: React.MouseEventHandler }) => (
  <FolderContainer onClick={onClick}>
    <FontAwesomeIcon color="#ffd986" icon={faFolder} size="3x" />
    <span className="folder-name">{folder.name}</span>
  </FolderContainer>
)
